import React, { Component } from 'react'

export class HamburgerSvg extends Component {
    render() {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={18}
                fill="none"
            >
                <path
                    fill="#000"
                    d="M24 9a1 1 0 0 1-1 1H1a1 1 0 0 1 0-2h22a1 1 0 0 1 1 1ZM1 2h22a1 1 0 1 0 0-2H1a1 1 0 0 0 0 2Zm22 14H1a1 1 0 0 0 0 2h22a1 1 0 0 0 0-2Z"
                />
            </svg>
        )
    }
}

export default HamburgerSvg
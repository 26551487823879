import React, { Component } from 'react'

export class FacebookSvg extends Component {
  render() {
    return (
        <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
  >
    <path
      fill="#000"
      d="M10 .25A9.75 9.75 0 1 0 19.75 10 9.76 9.76 0 0 0 10 .25Zm.75 17.965V12.25H13a.75.75 0 1 0 0-1.5h-2.25V8.5a1.5 1.5 0 0 1 1.5-1.5h1.5a.75.75 0 1 0 0-1.5h-1.5a3 3 0 0 0-3 3v2.25H7a.75.75 0 1 0 0 1.5h2.25v5.965a8.25 8.25 0 1 1 1.5 0Z"
    />
  </svg>
    )
  }
}

export default FacebookSvg
import React, { Component } from 'react'

export class TimerSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
      >
        <path
          fill="#000"
          d="M12 3.75a9 9 0 1 0 9 9 9.01 9.01 0 0 0-9-9Zm0 16.5a7.5 7.5 0 1 1 7.5-7.5 7.508 7.508 0 0 1-7.5 7.5Zm4.28-11.78a.748.748 0 0 1 0 1.06l-3.75 3.75a.75.75 0 1 1-1.06-1.06l3.75-3.75a.75.75 0 0 1 1.06 0ZM9 1.5a.75.75 0 0 1 .75-.75h4.5a.75.75 0 1 1 0 1.5h-4.5A.75.75 0 0 1 9 1.5Z"
        />
      </svg>
    )
  }
}

export default TimerSvg
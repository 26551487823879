import React, { Component } from 'react'

export class PhoneSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
      >
        <path
          fill="#000"
          d="M16.5 1.5h-9a2.25 2.25 0 0 0-2.25 2.25v16.5A2.25 2.25 0 0 0 7.5 22.5h9a2.25 2.25 0 0 0 2.25-2.25V3.75A2.25 2.25 0 0 0 16.5 1.5ZM6.75 6h10.5v12H6.75V6Zm.75-3h9a.75.75 0 0 1 .75.75v.75H6.75v-.75A.75.75 0 0 1 7.5 3Zm9 18h-9a.75.75 0 0 1-.75-.75v-.75h10.5v.75a.75.75 0 0 1-.75.75Z"
        />
      </svg>
    )
  }
}

export default PhoneSvg
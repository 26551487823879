import React, { Component } from 'react'
import { connect } from 'react-redux'
import Card1 from './cards/Card1';
import { getProducts } from '../actions/MainAction';

export class CatagoriesSections extends Component {
    componentDidMount() {
        this.props.getProducts();
    }
    render() {
        const { productData,
            background_img, icon, catagories_title, id } = this.props;
        // console.log(productData);

        const filteredData = productData?.filter(data => data.catagories_id === id);
        return (
            <div id={id} className='flex flex-col gap-[24px] '>
                <div className=' relative '>
                    <div className=' overflow-hidden '>
                        <img src={background_img} className='min-w-[1204px] h-[172px] rounded-[8px] ' />
                    </div>

                    <button className='bg-[#3E484F] p-[16px_64px] rounded-[8px] flex items-center justify-center gap-[10px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
                     max-[600px]:p-[16px_24px] max-[724px]:w-[80%] '>
                        <img src={icon} className='w-[32px] h-[32px] max-[568px]:' />

                        <p className='font-[600] text-[32px] leading-[40px] text-[#fff] max-[700px]:text-[24px] '>{catagories_title}</p>
                    </button>
                </div>

                <div className='grid grid-cols-4  gap-[36px] pt-[60px] max-[1200px]:pt-[48px] max-[724px]:pt-[36px] max-[1200px]:grid-cols-3 max-[1024px]:gap-[24px] max-[724px]:grid-cols-2 max-[768px]:gap-[16px] max-[380px]:grid-cols-1 max-[380px]:p-[0px_12px]'>
                    {filteredData?.map((data, i) => {
                        return (
                            <Card1
                                key={i}
                                id={data?.catagories_id}
                                products_title={data?.title}
                                products_image={data?.image_full_url}
                                price={data?.price}
                                weight={data?.weight}
                                description={data?.description}
                            />

                        )
                    })

                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    productData: state.Data.productData,
})

const mapDispatchToProps = { getProducts }

export default connect(mapStateToProps, mapDispatchToProps)(CatagoriesSections)
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getAboutUs } from '../actions/MainAction';
import Slider from 'react-slick';
import AboutUs from './cards/AboutUs'

export class MnPhotoSlider extends Component {
  componentDidMount() {
    this.props.getAboutUs();
  }
  render() {
    const settings = {
      dots: true, // Alt noktalama gizli
      infinite: true, // Sonsuz döngü
      speed: 2000, // Geçiş hızı
      slidesToShow: 3, // Gösterilecek slayt sayısı
      slidesToScroll: 1, // Kaydırılacak slayt sayısı
      autoplay: true, // Otomatik oynatma
      autoplaySpeed: 1500, // Otomatik oynatma hızı
      centerMode: true, // Merkezi düzenleme etkin
      centerPadding: "0px", // Kenarlardaki kayma boşluğu
      arrows: false,
      responsive: [
        {
          breakpoint: 2650,
          settings: {
            slidesToShow: 2,
            centerPadding: "23%",
          },
        },
        {
          breakpoint: 2247,
          settings: {
            slidesToShow: 2,
            centerPadding: "21%",
          },
        },
        {
          breakpoint: 2083,
          settings: {
            slidesToShow: 2,
            centerPadding: "19%",
          },
        },
        {
          breakpoint: 1966,
          settings: {
            slidesToShow: 2,
            centerPadding: "17%",
          },
        },
        {
          breakpoint: 1831,
          settings: {
            slidesToShow: 2,
            centerPadding: "15%",
          },
        },
        {
          breakpoint: 1744,
          settings: {
            slidesToShow: 2,
            centerPadding: "13%",
          },
        },
        {
          breakpoint: 1642,
          settings: {
            slidesToShow: 2,
            centerPadding: "11%",
          },
        },
        {
          breakpoint: 1566,
          settings: {
            slidesToShow: 2,
            centerPadding: "9%",
          },
        },
        {
          breakpoint: 1501,
          settings: {
            slidesToShow: 2,
            centerPadding: "7%",
          },
        },
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 1,
            centerPadding: "28%",
          },
        },
        {
          breakpoint: 1396,
          settings: {
            slidesToShow: 1,
            centerPadding: "26%",
          },
        },
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 1,
            centerPadding: "24%",
          },
        },
        {
          breakpoint: 1182,
          settings: {
            slidesToShow: 1,
            centerPadding: "22%",
          },
        },
        {
          breakpoint: 1098,
          settings: {
            slidesToShow: 1,
            centerPadding: "20%",
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            centerPadding: "19.5%",
          },
        },
        {
          breakpoint: 993,
          settings: {
            slidesToShow: 1,
            centerPadding: "17%",
          },
        },
        {
          breakpoint: 928,
          settings: {
            slidesToShow: 1,
            centerPadding: "15%",
          },
        },
        {
          breakpoint: 866,
          settings: {
            slidesToShow: 1,
            centerPadding: "13%",
          },
        },
        {
          breakpoint: 826,
          settings: {
            slidesToShow: 1,
            centerPadding: "10%",
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            centerPadding: "16%",
          },
        },
        {
          breakpoint: 753,
          settings: {
            slidesToShow: 1,
            centerPadding: "14%",
          },
        },
        {
          breakpoint: 709,
          settings: {
            slidesToShow: 1,
            centerPadding: "11%",
          },
        },
        {
          breakpoint: 658,
          settings: {
            slidesToShow: 1,
            centerPadding: "9%",
          },
        },
        {
          breakpoint: 622,
          settings: {
            slidesToShow: 1,
            centerPadding: "7%",
          },
        },
        {
          breakpoint: 596,
          settings: {
            slidesToShow: 1,
            centerPadding: "6%",
          },
        },
        {
          breakpoint: 578,
          settings: {
            slidesToShow: 1,
            centerPadding: "9%",
          },
        },
        {
          breakpoint: 542,
          settings: {
            slidesToShow: 1,
            centerPadding: "7%",
          },
        },
        {
          breakpoint: 520,
          settings: {
            slidesToShow: 1,
            centerPadding: "5%",
          },
        },
        {
          breakpoint: 495,
          settings: {
            slidesToShow: 1,
            centerPadding: "4%",
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            centerPadding: "2%",
          },
        },
        {
          breakpoint: 474,
          settings: {
            slidesToShow: 1,
            centerPadding: "6.5%",
          },
        },
        {
          breakpoint: 456,
          settings: {
            slidesToShow: 1,
            centerPadding: "5%",
          },
        },
        {
          breakpoint: 438,
          settings: {
            slidesToShow: 1,
            centerPadding: "3%",
          },
        },
        {
          breakpoint: 425,
          settings: {
            slidesToShow: 1,
            centerPadding: "13%",
          },
        },
        {
          breakpoint: 398,
          settings: {
            slidesToShow: 1,
            centerPadding: "11%",
          },
        },
        {
          breakpoint: 380,
          settings: {
            slidesToShow: 1,
            centerPadding: "9%",
          },
        },
        {
          breakpoint: 375,
          settings: {
            slidesToShow: 1,
            centerPadding: "9%",
          },
        },
        {
          breakpoint: 362,
          settings: {
            slidesToShow: 1,
            centerPadding: "7%",
          },
        },
        {
          breakpoint: 344,
          settings: {
            slidesToShow: 1,
            centerPadding: "5%",
          },
        },
        {
          breakpoint: 329,
          settings: {
            slidesToShow: 1,
            centerPadding: "3%",
          },
        },
        {
          breakpoint: 320,
          settings: {
            slidesToShow: 1,
            centerPadding: "3%",
          },
        },
        {
          breakpoint: 318,
          settings: {
            slidesToShow: 1,
            centerPadding: "2%",
          },
        },
        {
          breakpoint: 311,
          settings: {
            slidesToShow: 1,
            centerPadding: "0%",
          },
        },
      ],
    };

    const { aboutUsData } = this.props;
    // console.log(aboutUsData);

    return (
      <div className='pt-[40px]'>
        <div className=''>
          <Slider {...settings}>
            {aboutUsData?.map((data, i) => {
              return (

                <AboutUs
                  key={i}
                  image={data?.image_full_url}
                />


              )
            })

            }
          </Slider>
        </div >
      </div >
    )
  }
}

const mapStateToProps = (state) => ({
  aboutUsData: state.Data.aboutUsData,
})

const mapDispatchToProps = { getAboutUs }

export default connect(mapStateToProps, mapDispatchToProps)(MnPhotoSlider)
import React, { Component } from 'react'
import LocationSvg from '../icons/LocationSvg'
import { Link } from 'react-router-dom'
import PhoneSvg from '../icons/PhoneSvg'
import TimerSvg from '../icons/TimerSvg'

export class CompanyCard extends Component {
    formatPhoneNumber(phoneNumber) {
        return phoneNumber ? phoneNumber.replace(/\s+/g, '') : '';
    }

    isValidValue(value) {
        return value && value !== 'null' && value !== 'undefined' && value.trim() !== '';
    }
    render() {
        const { description, title, phone_number, phone_number3, clock, clock_2, iframe, home_number } = this.props;
        return (
            <div className='flex flex-col gap-[16px] p-[16px_0px]'>
                <div className='p-[0px_16px] flex items-center gap-[10px]'>
                    <LocationSvg />
                    {title}
                </div>

                {this.isValidValue(iframe) && (
                    <div className='map'>
                        <div className='map' dangerouslySetInnerHTML={{ __html: iframe }}></div>
                    </div>
                )}

                <div className='p-[0px_16px]'>
                    {this.isValidValue(description) && (
                        <div className='max-w-[247px] font-[400] text-[16px] leading-[24px] text-[#000]' dangerouslySetInnerHTML={{ __html: description }}></div>
                    )}
                </div>

                <div className='p-[0px_16px] flex flex-col gap-[8px]'>
                    <div className='flex items-center gap-[10px]'>
                        <PhoneSvg />
                        <span className='font-[600] text-[16px] leading-[24px] text-[#000]'>Mobil</span>
                    </div>

                    <div className='flex flex-col gap-[8px] cursor-pointer'>
                        {this.isValidValue(phone_number) && (
                            <Link to={`tel:${this.formatPhoneNumber(phone_number)}`}>{phone_number}</Link>
                        )}
                        {this.isValidValue(home_number) && (
                            <Link to={`tel:${this.formatPhoneNumber(home_number)}`}>{home_number}</Link>
                        )}
                        {this.isValidValue(phone_number3) && (
                            <Link to={`tel:${this.formatPhoneNumber(phone_number3)}`}>{phone_number3}</Link>
                        )}
                    </div>
                </div>

                <div className='p-[0px_16px] flex flex-col gap-[8px]'>
                    <div className='flex items-center gap-[10px]'>
                        <TimerSvg />
                        <span className='font-[600] text-[16px] leading-[24px] text-[#000]' >iş saatları</span>
                    </div>

                    <div className='flex flex-col gap-[8px]'>
                        {this.isValidValue(clock) && (
                            <span className='font-[400] text-[16px] leading-[24px] text-[#000]'>{clock}</span>
                        )}

                        {this.isValidValue(clock_2) && (
                            <span className='font-[400] text-[16px] leading-[24px] text-[#000]'>{clock_2}</span>
                        )}
                    </div>
                </div>
            </div>

        )
    }
}

export default CompanyCard
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import logo from '../images/logo.png'
import HamburgerSvg from '../icons/HamburgerSvg';
import LanguageNav from './LanguageNav';

export class Header extends Component {
    state = {
        inpRef: React.createRef()
    };

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
        window.addEventListener("click", this.handleClickOutside);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
        window.removeEventListener("click", this.handleClickOutside);
    }

    // handleScroll = () => {
    //     const nav = document.querySelector('.navbar');
    //     if (window.scrollY >= 50) {
    //         nav.style.position = 'fixed';
    //         nav.style.top = '0px';
    //     } else {
    //         nav.style.position = 'inherit';
    //     }
    // };

    handleClickOutside = (event) => {
        const { inpRef } = this.state;
        if (
            inpRef.current &&
            !inpRef.current.contains(event.target) &&
            !document.querySelector('.lbl-resp').contains(event.target)
        ) {
            this.closeNav(inpRef);
        }
    };

    closeNav = (inpRef) => {
        if (inpRef?.current) {
            inpRef.current.checked = false;
        }
    };

    render() {
        const { inpRef } = this.state;

        return (
            <div className='container flex justify-between items-center p-[32px_0px_44px_0px] max-[768px]:p-[24px]'>
                <div>
                    <Link to="/">
                        <img src={logo} className='w-[223px] h-[56px]' />
                    </Link>
                </div>


                <div className='menu w-[58%]'>
                    <input ref={inpRef} type="checkbox" id="onclick" className="hidden" />

                    <label htmlFor="onclick" className="lbl-resp">
                        <HamburgerSvg />
                    </label>



                    <ul className='flex items-center gap-[16px] navlist max-[768px]:w-full '>
                        <li className='font-[600] text-[16px] leading-[20px] text-[#8D8D8D]  max-[768px]:w-full max-[768px]:text-center '>
                            <Link to='/' onClick={() => this.closeNav(inpRef)} className='max-[768px]:pb-[16px]  block max-[768px]:border-b-[1px]  max-[768px]:border-b-[#8D8D8D] max-[768px]:text-[#000]'>Əsas səhifə</Link>
                        </li>
                        <li className='font-[600] text-[16px] leading-[20px] text-[#8D8D8D]  max-[768px]:w-full max-[768px]:text-center '>
                            <Link to="/menu" onClick={() => this.closeNav(inpRef)} className='max-[768px]:pb-[16px]  block max-[768px]:border-b-[1px]  max-[768px]:border-b-[#8D8D8D] max-[768px]:text-[#000]'>Menu</Link>
                        </li>
                        <li className='font-[600] text-[16px] leading-[20px] text-[#8D8D8D]  max-[768px]:w-full max-[768px]:text-center '>
                            <Link to="/company" onClick={() => this.closeNav(inpRef)} className='max-[768px]:pb-[16px]  block max-[768px]:border-b-[1px]  max-[768px]:border-b-[#8D8D8D] max-[768px]:text-[#000]'>Əlaqə</Link>
                        </li>

                        <li className='max-[768px]:mt-[64px] ml-auto'>
                            <LanguageNav />
                        </li>
                    </ul>


                </div>


            </div>
        )
    }
}

export default Header
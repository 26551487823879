import React, { Component } from 'react'

export class PhoneSvg2 extends Component {
    render() {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={32}
                height={32}
                fill="none"
            >
                <path
                    fill="#fff"
                    d="M18.034 5.741a.998.998 0 0 1 1.225-.707 10.777 10.777 0 0 1 7.707 7.707 1 1 0 1 1-1.932.517 8.794 8.794 0 0 0-6.291-6.292 1 1 0 0 1-.71-1.225Zm-.291 5.225c1.723.46 2.83 1.568 3.29 3.292a1 1 0 1 0 1.933-.517c-.64-2.395-2.312-4.067-4.707-4.707a1 1 0 0 0-.517 1.932Zm10.242 11.919A7.032 7.032 0 0 1 21 29c-9.925 0-18-8.075-18-18a7.033 7.033 0 0 1 6.115-6.985 2 2 0 0 1 2.078 1.19l2.64 5.894v.015a2 2 0 0 1-.16 1.886 1.367 1.367 0 0 1-.07.096L11 16.181c.936 1.903 2.926 3.875 4.854 4.814l3.042-2.589c.03-.025.061-.048.094-.07a2 2 0 0 1 1.896-.175l.017.008 5.888 2.639a2 2 0 0 1 1.194 2.077ZM26 22.635h-.014l-5.875-2.631-3.044 2.588c-.029.026-.06.049-.092.07a2 2 0 0 1-1.969.143c-2.341-1.131-4.675-3.447-5.807-5.764a2 2 0 0 1 .125-1.962.767.767 0 0 1 .071-.097L12 11.895 9.375 6.019v-.015A5.025 5.025 0 0 0 5 11a16.018 16.018 0 0 0 16 16 5.026 5.026 0 0 0 5-4.366v.001Z"
                />
            </svg>
        )
    }
}

export default PhoneSvg2
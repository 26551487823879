import React, { Component } from 'react';

import CloseSvg from '../icons/CloseSvg'
import PhoneSvg2 from '../icons/PhoneSvg2';
import WhatsappIcons2 from '../icons/WhatsappIcons2';
import InstagramSvg2 from '../icons/InstagramSvg2';
import { Link } from 'react-router-dom';

export class CallButton extends Component {
    state = {
        visible: false,
        rotating: false,
    };

    handleClick = () => {
        this.setState({ rotating: true });

        setTimeout(() => {
            this.setState((prevState) => ({
                visible: !prevState.visible, // Icon değiştirme
                rotating: false, // Animasyon tamamlandığında döndürmeyi durdur
            }));
        }, 500); // Animasyon süresi (Whatsapp için 500ms)

    };

    render() {
        const { visible, rotating } = this.state;

        return (
            <div className='  rounded-[8px] inline-block fixed bottom-[20px] right-[20px]'>
                {
                    visible ? (
                        <div className='flex flex-col items-center gap-[14px] frd'>
                            <Link to="tel:+994504905905" className='p-[16px] rounded-[50%] bg-[#666666] '>
                                <PhoneSvg2 />
                            </Link>
                            <Link to="https://wa.me/+994504905905" target='__blank' className='p-[16px] rounded-[50%] bg-[#666666]'>
                                <WhatsappIcons2 />
                            </Link>
                            <Link to="https://www.instagram.com/maqqash.baku/" target='__blank' className='p-[16px] rounded-[50%] bg-[#666666]' >
                                <InstagramSvg2 />
                            </Link>
                        </div>
                    ) : null
                }
                <div
                    onClick={this.handleClick}
                    className='flex items-center gap-[16px] bg-[var(--white)] rounded-[8px] p-[8px] cursor-pointer'>
                    <button className='play_btn'>
                        {
                            !visible ? (
                                <div className={` p-[16px] bg-[#666666] rounded-[50%]  icon ${rotating ? 'rotate-animation' : ''}`}>
                                    <PhoneSvg2 />

                                </div>
                            ) : (
                                <div className={`icon bg-[#666666] p-[16px] rounded-[50%] ${rotating ? 'rotate-in' : ''}`}>
                                    <CloseSvg />
                                </div>
                            )
                        }
                    </button>
                </div>
            </div>
        );
    }
}

export default CallButton;
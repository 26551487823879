import React, { Component } from 'react'

export class CloseSvg extends Component {
    render() {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={32}
                height={32}
                fill="none"
            >
                <path
                    fill="#fff"
                    d="M25.708 24.293a1 1 0 0 1-1.415 1.415L16 17.414l-8.292 8.294a1 1 0 0 1-1.415-1.415L14.586 16 6.293 7.708a1 1 0 1 1 1.415-1.415L16 14.586l8.293-8.293a1 1 0 1 1 1.415 1.415L17.414 16l8.294 8.293Z"
                />
            </svg>
        )
    }
}

export default CloseSvg
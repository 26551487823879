import React, { Component } from 'react'

export class WhatsappSvg extends Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
      >
        <path
          fill="#000"
          d="m17.586 13.579-3-1.5a.75.75 0 0 0-.75.047l-1.377.918a3.802 3.802 0 0 1-1.5-1.5l.918-1.377a.75.75 0 0 0 .047-.75l-1.5-3A.75.75 0 0 0 9.75 6 3.75 3.75 0 0 0 6 9.75 8.26 8.26 0 0 0 14.25 18 3.75 3.75 0 0 0 18 14.25a.75.75 0 0 0-.414-.671ZM14.25 16.5A6.758 6.758 0 0 1 7.5 9.75a2.25 2.25 0 0 1 1.808-2.207L10.385 9.7l-.916 1.364a.75.75 0 0 0-.069.704 5.294 5.294 0 0 0 2.827 2.826.75.75 0 0 0 .71-.062l1.37-.913 2.156 1.076A2.25 2.25 0 0 1 14.25 16.5ZM12 2.25a9.75 9.75 0 0 0-8.608 14.332l-1.064 3.193a1.5 1.5 0 0 0 1.897 1.897l3.193-1.064A9.75 9.75 0 1 0 12 2.25Zm0 18a8.237 8.237 0 0 1-4.13-1.107.75.75 0 0 0-.614-.063L3.75 20.25l1.17-3.506a.75.75 0 0 0-.063-.613A8.25 8.25 0 1 1 12 20.25Z"
        />
      </svg>
    )
  }
}

export default WhatsappSvg
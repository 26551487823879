import axios from "axios";
import { MAIN_API } from '../MAIN_API'
import { changeStateValue } from "../redux/MainReducer";

export const getContent = (lang_id = "1") => async dispatch => {
    dispatch(changeStateValue({ name: 'loader', value: true }))
    return await axios.get(`${MAIN_API}/content`, {
        params: { lang_id: lang_id }
    }).then(resp => {
        dispatch(changeStateValue({
            name: 'contentData',
            value: resp?.data
        }))
    }).catch(err => {
        console.log(err.response)   
        dispatch(changeStateValue({ name: 'loader', value: false }))
    })
}

export const getVideo = () => async dispatch => {
    dispatch(changeStateValue({ name: 'loader', value: true }));

    return await axios.get(`${MAIN_API}/video`, {

    })
        .then(resp => {
            dispatch(changeStateValue({
                name: 'videoData',
                value: resp?.data
            }));
        })
        .catch(err => {
            console.log(err.response);
            dispatch(changeStateValue({ name: 'loader', value: false }));
        });
};


export const getAboutUs = () => async dispatch => {
    dispatch(changeStateValue({ name: 'loader', value: true }));

    return await axios.get(`${MAIN_API}/aboutus`, {

    })
        .then(resp => {
            dispatch(changeStateValue({
                name: 'aboutUsData',
                value: resp?.data
            }));
        })
        .catch(err => {
            console.log(err.response);
            dispatch(changeStateValue({ name: 'loader', value: false }));
        });
};



export const getMainProducts = () => async dispatch => {
    dispatch(changeStateValue({ name: 'loader', value: true }));

    return await axios.get(`${MAIN_API}/main-products`, {

    })
        .then(resp => {
            dispatch(changeStateValue({
                name: 'mainProductsData',
                value: resp?.data
            }));
        })
        .catch(err => {
            console.log(err.response);
            dispatch(changeStateValue({ name: 'loader', value: false }));
        });
};


export const getCatagories = () => async dispatch => {
    dispatch(changeStateValue({ name: 'loader', value: true }));

    return await axios.get(`${MAIN_API}/catagory`, {

    })
        .then(resp => {
            dispatch(changeStateValue({
                name: 'catagoryData',
                value: resp?.data
            }));
        })
        .catch(err => {
            console.log(err.response);
            dispatch(changeStateValue({ name: 'loader', value: false }));
        });
};

export const getProducts = () => async dispatch => {
    dispatch(changeStateValue({ name: 'loader', value: true }));

    return await axios.get(`${MAIN_API}/products`, {

    })
        .then(resp => {
            dispatch(changeStateValue({
                name: 'productData',
                value: resp?.data
            }));
        })
        .catch(err => {
            console.log(err.response);
            dispatch(changeStateValue({ name: 'loader', value: false }));
        });
};

export const getCompany = () => async dispatch => {
    dispatch(changeStateValue({ name: 'loader', value: true }));

    return await axios.get(`${MAIN_API}/company`, {

    })
        .then(resp => {
            dispatch(changeStateValue({
                name: 'companyData',
                value: resp?.data
            }));
        })
        .catch(err => {
            console.log(err.response);
            dispatch(changeStateValue({ name: 'loader', value: false }));
        });
};


export const getMenuHeaderImage = () => async dispatch => {
    dispatch(changeStateValue({ name: 'loader', value: true }));
    try {
        const resp = await axios.get(`${MAIN_API}/menu-header-image`);
        // console.log("API Response:", resp.data);
        dispatch(changeStateValue({
            name: 'menuImageData',
            value: resp.data
        }));
    } catch (err) {
        console.error("API Error:", err.response);
        dispatch(changeStateValue({ name: 'loader', value: false }));
    }
};
import React, { Component } from 'react'
import MenuIconSvg from '../icons/MenuIconSvg'
import { Link } from 'react-router-dom'

export class MenuBUtton extends Component {
  render() {
    return (
      <Link to="/menu" className='fixed bottom-[20px] left-[20px] z-[800] p-[14px] bg-[#fff] rounded-[50%]'>
          <MenuIconSvg />
      </Link>
    )
  }
}

export default MenuBUtton
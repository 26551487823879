export const initialState = {
    aboutUsData: [],
    catagoryData: [],
    productData: [],
    companyData: [],
    menuImageData: [],
    videoData: [],
    mainProductsData: [],
    contentData:[],

}
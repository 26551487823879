import React, { Component } from 'react'

export class MenuButton extends Component {
  render() {
    const { icons, title, id, onClick } = this.props;
    return (
      <button onClick={() => onClick(id)} className='p-[16px_32px] bg-[#fff] rounded-[8px] flex items-center gap-[12px]'>
        <img src={icons} className='w-[24px] h-[24px]' />
        <h3 className='font-[600] text-[16px] leading-[20px] text-[#000]'>
          {title}
        </h3>
      </button>
    )
  }
}

export default MenuButton
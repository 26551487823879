import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import PhoneSvg from '../components/icons/PhoneSvg'
import TimerSvg from '../components/icons/TimerSvg'
import CompanyCard from '../components/cards/CompanyCard'
import { getCompany } from '../actions/MainAction'
import CallButton from '../components/cards/CallButton'
import MenuButton from '../components/MenuButton'
import MenuBUtton from '../components/cards/MenuButton'

export class Company extends Component {
    componentDidMount() {
        this.props.getCompany();
    }
    render() {
        const { companyData } = this.props;
        // console.log(companyData);

        return (
            <div className='pt-[47px] container'>
                {/* <div className='flex flex-col gap-[28px] w-[33%] max-[1020px]:w-full max-[670px]:items-center'>
                        <div className='p-[0px_16px] border-b-[1px] border-b-[#000] w-full '>
                            <h3 className='font-[600] text-[32px] leading-[40px] text-[#000] pb-[12px] max-[670px]:text-center'>Əlaqə</h3>
                        </div>

                        <div className='p-[0px_16px] flex flex-col gap-[20px]'>
                            <div className=' flex flex-col gap-[8px]'>
                                <div className='flex items-center gap-[10px]'>
                                    <PhoneSvg />
                                    <span className='font-[600] text-[16px] leading-[24px] text-[#000]'>Mobil</span>
                                </div>
                                <div>
                                    <Link className='font-[400] text-[16px] leading-[24px] text-[#000]'>+994 50 255 59 19</Link>
                                </div>
                            </div>

                            <div className=' flex flex-col gap-[8px]'>
                                <div className='flex items-center gap-[10px]'>
                                    <TimerSvg />
                                    <span className='font-[600] text-[16px] leading-[24px] text-[#000]'>iş saatları</span>
                                </div>
                                <p className='font-[400] text-[16px] leading-[24px] text-[#000]'>
                                    B.e-B. | 09:00-23:00
                                </p>
                            </div>
                        </div>

                    </div> */}


                <div className='w-full pt-[20px] max-[1020px]:w-full'>
                    <div className='p-[0px_16px] border-b-[1px] border-b-[#000] w-full '>
                        <h3 className='font-[600] text-[32px] leading-[40px] text-[#000] pb-[12px] max-[670px]:text-center'>Filiallar</h3>
                    </div>

                    <div className='grid grid-cols-4 gap-[36px] max-[670px]:flex-col'>
                        {companyData?.map((data, i) => {
                            return (
                                <CompanyCard
                                    key={i}
                                    title={data?.title}
                                    description={data?.description}
                                    phone_number={data?.phone_number}
                                    home_number={data?.home_number}
                                    phone_number3={data?.phone_number3}
                                    clock={data?.clock}
                                    clock_2={data?.clock_2}
                                    iframe={data?.location_iframe}
                                />

                            )
                        })

                        }
                    </div>
                </div>

                <MenuBUtton />
                <CallButton />


            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    companyData: state.Data.companyData

})

const mapDispatchToProps = { getCompany }

export default connect(mapStateToProps, mapDispatchToProps)(Company)
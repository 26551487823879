import React, { Component } from 'react'

export class InstagramSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
      >
        <path
          fill="#000"
          d="M12 7.5a4.5 4.5 0 1 0 0 9 4.5 4.5 0 0 0 0-9Zm0 7.5a3 3 0 1 1 0-5.999A3 3 0 0 1 12 15Zm4.5-12.75h-9A5.256 5.256 0 0 0 2.25 7.5v9a5.256 5.256 0 0 0 5.25 5.25h9a5.256 5.256 0 0 0 5.25-5.25v-9a5.256 5.256 0 0 0-5.25-5.25Zm3.75 14.25a3.75 3.75 0 0 1-3.75 3.75h-9a3.75 3.75 0 0 1-3.75-3.75v-9A3.75 3.75 0 0 1 7.5 3.75h9a3.75 3.75 0 0 1 3.75 3.75v9ZM18 7.125a1.125 1.125 0 1 1-2.25 0 1.125 1.125 0 0 1 2.25 0Z"
        />
      </svg>
    )
  }
}

export default InstagramSvg
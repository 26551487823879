import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'
import CatagoriesSections from '../components/CatagoriesSections'
import MenuButton from '../components/MenuButton'
import catagoryData, { getCatagories, getMenuHeaderImage } from '../actions/MainAction'
import CallButton from '../components/cards/CallButton'

export class Menu extends Component {
  sectionRefs = {};

  componentDidMount() {
    this.props.getCatagories();
    this.props.getMenuHeaderImage();

  }

  scrollToSection = (id) => {
    const sectionRef = this.sectionRefs[id];
    if (sectionRef && sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  render() {
    const { catagoryData, menuImageData } = this.props;
    // console.log(menuImageData?.id);

    return (
      <main>
        <header className='w-full min-h-[400px]  flex justify-center items-center relative'>
          {menuImageData?.map((data, i) => {
            return (
              <img src={data?.image_full_url} key={i} className='h-[400px] w-full' />
            )
          })

          }

          <div className='container flex flex-wrap justify-center gap-[16px] absolute'>
            {
              catagoryData?.map((data, i) => {
                return (
                  <MenuButton
                    key={i}
                    id={data?.id}
                    icons={data?.icon_dark}
                    title={data?.title}
                    onClick={() => this.scrollToSection(data?.id)}
                  />

                )
              })
            }

          </div>
        </header>

        <section className='container pt-[60px] flex flex-col gap-[60px]'>

          {catagoryData?.map((data, i) => {
            if (!this.sectionRefs[data?.id]) {
              this.sectionRefs[data.id] = createRef();
            }
            return (
              <div key={i} ref={this.sectionRefs[data.id]} className='flex flex-col gap-[60px]'>
                <CatagoriesSections
                  key={i}
                  background_img={data?.background_img}
                  icon={data?.icon}
                  catagories_title={data?.title}
                  id={data?.id}
                />
              </div>
            )
          })

          }

        </section>

        <CallButton />
      </main>
    )
  }
}

const mapStateToProps = (state) => ({
  catagoryData: state.Data.catagoryData,
  menuImageData: state.Data.menuImageData,
})

const mapDispatchToProps = { getCatagories, getMenuHeaderImage }

export default connect(mapStateToProps, mapDispatchToProps)(Menu)
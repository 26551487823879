import React, { Component } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import MainPage from './pages/MainPage'
import Header from './components/Header/Header'
import Footer from './components/Header/Footer'
import Menu from './pages/Menu';
import Company from './pages/Company';
import { getContent } from './actions/MainAction';
import { connect } from 'react-redux';

export class App extends Component {
  componentDidMount() {
    this.props.getContent();
  }
  render() {
    const {contentData} = this.props;
    return (
      <div >
        <BrowserRouter>
          <Header />
          <Routes>

            <Route path='/' element={<MainPage />} />
            <Route path='/menu' element={<Menu />} />
            <Route path='/company' element={<Company />} />

          </Routes>
          <Footer />
        </BrowserRouter>

      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  contentData: state.Data.contentData
})

const mapDispatchToProps = {getContent};
export default connect(mapStateToProps, mapDispatchToProps) ( App)
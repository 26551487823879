import React, { Component } from 'react'
import logo from '../images/footerLogo.png'
import { Link } from 'react-router-dom'
import InstagramSvg from '../icons/InstagramSvg'
import FacebookSvg from '../icons/FacebookSvg'
import WhatsappSvg from '../icons/WhatsappSvg'


export class Footer extends Component {
    render() {
        return (
            <footer className='pt-[100px]'>
                <div className='container'>
                    <div className='flex justify-between gap-[32px] p-[20px_0px] max-[768px]:justify-center'>
                        <div className='flex items-center gap-[32px]'>
                            <div className='max-[768px]:hidden'>
                                <img src={logo} className='w-[40px] h-[32px]' />
                            </div>
                            <ul className='flex items-center gap-[32px] max-[768px]:hidden'>
                                <li>
                                    <Link to="/" className="font-[600] text-[16px] leading-[20px] text-[#8D8D8D]">
                                        Əsas səhifə
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/menu" className="font-[600] text-[16px] leading-[20px] text-[#8D8D8D]">
                                        Menu
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/company" className="font-[600] text-[16px] leading-[20px] text-[#8D8D8D]">
                                        Əlaqə
                                    </Link>
                                </li>
                            </ul>
                        </div>

                        <div className='flex items-center gap-[32px] max-[330px]:gap-[20px] max-[330px]:flex-col'>
                            <p className='font-[700] text-[16px] leading-[24px] text-[#000]'>İŞ SAATLARI</p>
                            <p className='font-[400] text-[16px] leading-[24px] text-[#000]'>B.e-B. | 09:00-23:00</p>
                        </div>

                        <div className='flex items-center gap-[32px] max-[768px]:hidden'>
                            <Link to="https://www.instagram.com/maqqash.baku/" target='__blank'>
                                <InstagramSvg />
                            </Link>
                            <Link to="https://www.facebook.com/vanilla.az" target='__blank'>
                                <FacebookSvg />
                            </Link>
                            <Link to="https://wa.me/+994504905905" target='__blank'>
                                <WhatsappSvg width="24" height="24" />
                            </Link>
                        </div>

                    </div>
                </div>

                <div className='hidden bg-[#F5F5F5] p-[20px_0px]  max-[768px]:flex justify-center items-center gap-[12px]' >
                    <img src={logo} className='w-[40px] h-[32px]' />
                    <p className='font-[300] text-[12px] leading-[16px] text-[#000]'>
                        © 2024 All Right Reserved. Maqqash Cafe
                    </p>
                </div>


                <div className='bg-[#F5F5F5] max-[768px]:hidden'>
                    <div className='container'>
                        <div className='flex justify-between p-[16px_0px]'>
                            <p className='font-[300] text-[12px] leading-[16px] text-[#000]'>
                                © 2024 All Right Reserved. Maqqash Cafe Pastry
                            </p>

                            <Link to="https://www.instagram.com/despro.az/" target='__blank' className='font-[300] text-[12px] leading-[16px] text-[#000]'>
                                developed by <span className='font-[600] text-[12px] leading-[16px] text-[#000]'>despro.az</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer
import React, { Component } from 'react'
import InstagramSvg from '../icons/InstagramSvg'
import FacebookSvg from '../icons/FacebookSvg'
import WhatsappSvg from '../icons/WhatsappSvg'

export class LanguageNav extends Component {
    render() {
        return (
            <div className='flex max-[768px]:items-center max-[768px]:flex-col max-[768px]:gap-[60px]'>
                {/* <div className='gap-[16px] flex max-[768px]:items-center justify-center'>
                    <button className='font-[600] text-[16px] leading-[20px] text-[#8D8D8D] 
                        max-[768px]:p-[8px_32px] max-[768px]:border-[1px] max-[768px]:border-[#000] max-[768px]:rounded-[4px] max-[768px]:text-[#000]'>
                        AZ
                    </button>
                    <button className='font-[600] text-[16px] leading-[20px] text-[#8D8D8D] 
                        max-[768px]:p-[8px_32px] max-[768px]:border-[1px] max-[768px]:border-[#000] max-[768px]:rounded-[4px] max-[768px]:text-[#000]'>
                        RU
                    </button>
                </div> */}

                <div className='max-[768px]:flex justify-center items-center gap-[32px] hidden '>

                    <InstagramSvg />
                    <FacebookSvg />
                    <WhatsappSvg />
                </div>

                <div className='mt-[40px] max-[768px]:flex flex-col items-center gap-[16px] hidden'>
                    <p className='font-[700] text-[16px] leading-[24px] text-[#000]'>İŞ SAATLARI</p>
                    <p className='font-[400] text-[16px] leading-[24px] text-[#000]'>B.e-B. | 09:00-23:00</p>
                </div>
            </div>

        )
    }
}

export default LanguageNav
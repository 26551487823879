import React, { Component } from 'react'

export class AboutUs extends Component {
    render() {
        const { image } = this.props;
        return (
            <div className='w-[634px] h-[379px] p-[0px_18px] max-[769px]:w-[533px] max-[801px]:h-[360px] max-[426px]:w-[324px] max-[426px]:h-[214.441640804px] '>
                <img className='w-[634px] h-[357px]  max-[769px]:w-[533px] max-[769px]:h-[300px] max-[570px]:w-[434px]  max-[570px]:h-[244.381703469] max-[480px]:w-[385px] max-[480px]:h-[216.79022082px] max-[426px]:w-[324px] max-[426px]:h-[182.441640804px]  rounded-[16px] mb-[32px]' src={image} />
            </div>
        )
    }
}

export default AboutUs